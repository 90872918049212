@import url('https://necolas.github.io/normalize.css/8.0.1/normalize.css');

@import url("https://fonts.googleapis.com/css2?family=Anton&family=Dosis:wght@200;400;600;800&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Nunito+Sans:ital,opsz,wght@0,6..12,200;0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;0,6..12,800;0,6..12,900;0,6..12,1000;1,6..12,200;1,6..12,300;1,6..12,400;1,6..12,500;1,6..12,600;1,6..12,700;1,6..12,800;1,6..12,900;1,6..12,1000&family=PT+Sans:wght@400;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css");

html {
    scroll-behavior: smooth;
    scroll-padding-top: 70px;
  }

body {
    margin: 0;
    font-family: Inter, sans-serif;
}


#heroVideo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    transition: background-color 1s ease-in-out;
}


.logo_mask {
    display: inline-block;

    -webkit-mask-image: url('./components/images/LUKAS BURBA.svg');
    mask-image: url('./components/images/LUKAS BURBA.svg');

    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  
    -webkit-mask-position: center;
    mask-position: center;

    -webkit-mask-size: 100%;
    mask-size: 99%;
}

@media (max-width: 768px) {
    
    .video_holder {
        width: 100%; 
        height: auto; 
        margin: 0 auto; 
    }

    .logo_mask {
        -webkit-mask-size: 95%; 
        mask-size: 95%; 
        -webkit-mask-position: center;
        mask-position: center;
    }

    #heroVideo > video {
        width: 500px; 
        height: auto; 
    }
}
